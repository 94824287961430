

import __layout_0 from '/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/default.vue'
export const layouts = {
'auth': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/auth.vue'),
'basic': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/basic.vue'),
'default': __layout_0,
'whitelisting': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/whitelisting.vue'),
'components/BasicNavBar': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/BasicNavBar.vue'),
'components/DefaultLayoutWithVerticalNav': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/DefaultLayoutWithVerticalNav.vue'),
'components/Footer': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/Footer.vue'),
'components/NavbarThemeSwitcher': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/OrganizationSwitch': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/OrganizationSwitch.vue'),
'components/PartnerCreditsBar': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/PartnerCreditsBar.vue'),
'components/PartnerUserMenu': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/PartnerUserMenu.vue'),
'components/TrialEndedBanner': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/TrialEndedBanner.vue'),
'components/UpgradeSubscriptionButton': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/UpgradeSubscriptionButton.vue'),
'components/UserMenu': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/UserMenu.vue'),
'components/WalkthroughSummary': () => import('/home/pierre/Workspace/arsen-frontend-vue3/src/layouts/components/WalkthroughSummary.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
